import { observer } from "mobx-react";
import Prolog from "components/Prolog";
import { useCallback, useEffect, useState } from "react";

interface Props {
  page: number;
  mobile: boolean;
}

const PrologPageContainer = ({ page, mobile }: Props) => {
  const [pageP, setPageP] = useState<number>(0);
  const [width, setWidth] = useState<number>(0);

  const prologChange = useCallback((page: number) => {
    setPageP(page);
  }, []);

  const prologBtnChange = useCallback(
    (type: string) => {
      if (type === "next") {
        if (pageP < 6) {
          setPageP(pageP + 1);
        }
      } else if (type === "prev") {
        if (pageP > 0) {
          setPageP(pageP - 1);
        }
      }
    },
    [pageP]
  );

  const checkWindow = useCallback(() => {
    const inwidth = window.innerWidth;
    setWidth(inwidth);
    if (inwidth < 1000) {
      setPageP(1);
    } else {
      prologChange(0);
    }
  }, [width, page]);

  // useEffect(() => {
  //   if (page) {
  //     let timer: NodeJS.Timeout;
  //     if (pageP === 1 || pageP === 3 || pageP === 5)
  //       timer = setTimeout(() => {
  //         setPageP(pageP + 1);
  //       }, 5000);
  //     return () => clearTimeout(timer);
  //   }
  // }, [pageP]);

  useEffect(() => {
    window.addEventListener("resize", checkWindow);
    return () => {
      window.removeEventListener("resize", checkWindow);
    };
  }, []);

  useEffect(() => {
    const width = window.innerWidth;
    setWidth(width);
    checkWindow();
  }, []);

  return (
    <>
      <Prolog
        page={pageP}
        prologChange={prologChange}
        prologBtnChange={prologBtnChange}
        width={width}
        mobile={mobile}
      />
    </>
  );
};

export default observer(PrologPageContainer);
