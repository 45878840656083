import List from "assets/convert";

export const snsTwiiter = () => {
  switch (List.img.Country) {
    case "KR": {
      const url = "https://twitter.com/GrimLightkr";
      return url;
    }
    case "JP": {
      const url = "https://twitter.com/GrimLightjp";
      return url;
    }
    case "US":
    default: {
      const url = "https://twitter.com/GrimLightEN";
      return url;
    }
  }
};

export const platformGoogle = () => {
  switch (List.img.Country) {
    case "KR": {
      const url =
        "https://play.google.com/store/apps/details?id=com.eightstudio.grimlight";
      return url;
    }
    case "JP": {
      const url =
        "https://play.google.com/store/apps/details?id=com.eightstudio.grimlight.jp&hl=ja&gl=jp";
      return url;
    }
    case "US":
    default: {
      const url =
        "https://play.google.com/store/apps/details?id=com.eightstudio.grimlight";
      return url;
    }
  }
};

export const platformApple = () => {
  switch (List.img.Country) {
    case "KR": {
      const url =
        "https://apps.apple.com/kr/app/%EB%B0%B1%EC%9D%BC%EB%AA%BD%ED%99%94/id1581756356?platform=iphone";
      return url;
    }
    case "JP": {
      const url = "https://apps.apple.com/jp/app/id6449136332";
      return url;
    }
    case "US":
    default: {
      const url =
        "https://apps.apple.com/us/app/grimlight/id1581756356?platform=iphone";
      return url;
    }
  }
};

export const mainPvUrl = () => {
  switch (List.img.Country) {
    case "JP": {
      const url = "https://www.youtube.com/watch?v=h0EimRJ8Sqk";
      return url;
    }
    case "KR":
    case "US":
    default: {
      const url = "https://www.youtube.com/watch?v=xbgO02CPye0";
      return url;
    }
  }
};
