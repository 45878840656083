import { mainPvUrl } from "util/convertAdress";
import "./PvOver.scss";
import ReactPlayer from "react-player";

interface Props {
  setPvModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const PvOver = ({ setPvModal }: Props) => {
  return (
    <>
      <div
        className="PVModal"
        onClick={() => {
          setPvModal(false);
        }}
      >
        <ReactPlayer url={mainPvUrl()} playing controls />
      </div>
    </>
  );
};

export default PvOver;
