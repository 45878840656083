import List from "assets/convert";

export const imagesLoader = [
  List.img.Hero1,
  List.img.AliceTxt,
  List.img.AliceVoice,
  List.img.Hero2,
  List.img.SnowTxt,
  List.img.SnowVoice,
  List.img.Hero3,
  List.img.KaguyaTxt,
  List.img.KaguyaVoice,
  List.img.Hero4,
  List.img.DraculaTxt,
  List.img.DraculaVoice,
  List.img.Hero5,
  List.img.RoseTxt,
  List.img.RoseVoice,
  List.img.Hero6,
  List.img.MermaidTxt,
  List.img.MermaidVoice,
  List.img.Hero7,
  List.img.RabbitTxt,
  List.img.RabbitVoice,
];
