import List from "assets/convert";
import Twitter from "assets/img/icon/Icon_Twitter.png";
import Cafe from "assets/img/icon/Icon_Cafe.png";
import Discord from "assets/img/icon/Icon_Discord.png";
import Youtube from "assets/img/icon/Icon_Youtube.png";
import Copy from "assets/logo/Copyright_Mini.svg";
import MenuIcon from "assets/img/icon/Menu.png";
import MenuLine from "assets/img/icon/menuline.svg";

import "./MobileMenu.scss";
import React from "react";

interface Props {
  onClickMenuChange: (url: string) => void;
  setIsShow: React.Dispatch<React.SetStateAction<boolean>>;
}

const MobileMenu = ({ onClickMenuChange, setIsShow }: Props) => {
  return (
    <>
      <img
        src={MenuIcon}
        className="MobileMenuIcon"
        onClick={() => setIsShow(true)}
      />
      <div className="MobileMenu mobilHide" onClick={() => setIsShow(false)}>
        <div className="MobileMenu-area">
          <img src={List.img.LogoWhite} className="MobileMenu-area-logo" />

          <div className="MobileMenu-area-menu">
            <div className="MobileMenu-area-menu-line">
              <div className="MobileMenu-area-menu-line-die"></div>
              <div className="MobileMenu-area-menu-line-solid"></div>
              <div className="MobileMenu-area-menu-line-dier"></div>
            </div>
            <div
              className={
                List.img.Country === "KR"
                  ? "MobileMenu-area-menu-card"
                  : "MobileMenu-area-menu-cardUSM"
              }
              onClick={() => {
                onClickMenuChange("main");
              }}
            >
              <img src={List.img.MenuMain} alt="" />
            </div>
            <div className="MobileMenu-area-menu-line">
              <div className="MobileMenu-area-menu-line-die"></div>
              <div className="MobileMenu-area-menu-line-solid"></div>
              <div className="MobileMenu-area-menu-line-dier"></div>
            </div>
            <div
              className="MobileMenu-area-menu-card"
              onClick={() => {
                onClickMenuChange("prolog");
              }}
            >
              <img src={List.img.MenuPrologue} alt="" />
            </div>
            <div className="MobileMenu-area-menu-line">
              <div className="MobileMenu-area-menu-line-die"></div>
              <div className="MobileMenu-area-menu-line-solid"></div>
              <div className="MobileMenu-area-menu-line-dier"></div>
            </div>
            <div
              className="MobileMenu-area-menu-card"
              onClick={() => {
                onClickMenuChange("world");
              }}
            >
              <img src={List.img.MenuWorld} alt="" />
            </div>
            <div className="MobileMenu-area-menu-line">
              <div className="MobileMenu-area-menu-line-die"></div>
              <div className="MobileMenu-area-menu-line-solid"></div>
              <div className="MobileMenu-area-menu-line-dier"></div>
            </div>
            <div
              className={
                List.img.Country === "KR"
                  ? "MobileMenu-area-menu-card"
                  : "MobileMenu-area-menu-cardUSh"
              }
              onClick={() => {
                onClickMenuChange("merhen");
              }}
            >
              <img src={List.img.MenuCharector} alt="" />
            </div>
            <div className="MobileMenu-area-menu-line">
              <div className="MobileMenu-area-menu-line-die"></div>
              <div className="MobileMenu-area-menu-line-solid"></div>
              <div className="MobileMenu-area-menu-line-dier"></div>
            </div>
            <div
              className="MobileMenu-area-menu-card"
              onClick={() => {
                onClickMenuChange("res");
              }}
            >
              <img src={List.img.MenuRes} alt="" />
            </div>
            <div className="MobileMenu-area-menu-line">
              <div className="MobileMenu-area-menu-line-die"></div>
              <div className="MobileMenu-area-menu-line-solid"></div>
              <div className="MobileMenu-area-menu-line-dier"></div>
            </div>
          </div>
          <div className="MobileMenu-area-iconList">
            <a href="https://twitter.com/grimlightkr" target="_blank">
              <img
                src={Twitter}
                alt=""
                className="MobileMenu-area-iconList-icon"
              />
            </a>
            {List.img.Country === "KR" ? (
              <a href="https://cafe.naver.com/grimlight" target="_blank">
                <img
                  src={Cafe}
                  alt=""
                  className="MobileMenu-area-iconList-cafe"
                />
              </a>
            ) : (
              <a href="https://discord.gg/2wh4Yr5dNg" target="_blank">
                <img
                  src={Discord}
                  alt=""
                  className="MobileMenu-area-iconList-cafe"
                />
              </a>
            )}
            <a
              href="https://www.youtube.com/channel/UCPQXLEuUWVx0BPklqUS65eA"
              target="_blank"
            >
              <img
                src={Youtube}
                alt=""
                className="MobileMenu-area-iconList-icon"
              />
            </a>
          </div>
          <div className="MobileMenu-area-license">
            <a href="https://www.eightstudio.co.kr/blank-6" target="_blank">
              <img src={List.img.License} alt="" />
            </a>
            <a href="https://www.eightstudio.co.kr/blank-7" target="_blank">
              <img src={List.img.Privacy} alt="" />
            </a>
          </div>
          <img src={Copy} className="MobileMenu-area-copy" />
        </div>
      </div>
    </>
  );
};

export default React.memo(MobileMenu);
