import Chapter1 from "assets/img/Chapter_Symbol0.png";
import Chapter2 from "assets/img/Chapter_Symbol1.png";
import Chapter3 from "assets/img/Chapter_Symbol5.png";
import List from "assets/convert";
import Arrow from "assets/img/icon/Arrow_Flat.png";
import React, { memo } from "react";
import BACK from "assets/img/icon/BACK.svg";

import "./Prolog.scss";

interface Props {
  page: number;
  prologChange: (page: number) => void;
  prologBtnChange: (type: string) => void;
  width: number;
  mobile: boolean;
}

const Prolog = ({
  page,
  prologChange,
  prologBtnChange,
  width,
  mobile,
}: Props) => {
  const textConvert = () => {
    switch (page) {
      case 1:
        return (
          <img
            src={mobile ? List.img.PrologueM1 : List.img.Prologue1}
            alt=""
            className="chapter-area-info"
          />
        );

      case 2:
        return (
          <img
            src={mobile ? List.img.PrologueM2 : List.img.Prologue2}
            alt=""
            className="chapter-area-info"
          />
        );

      case 3:
        return (
          <img
            src={mobile ? List.img.PrologueM3 : List.img.Prologue3}
            alt=""
            className="chapter-area-info"
          />
        );

      case 4:
        return (
          <img
            src={mobile ? List.img.PrologueM4 : List.img.Prologue4}
            alt=""
            className="chapter-area-info"
          />
        );

      case 5:
        return (
          <img
            src={mobile ? List.img.PrologueM5 : List.img.Prologue5}
            alt=""
            className="chapter-area-info"
          />
        );

      case 6:
        return (
          <img
            src={mobile ? List.img.PrologueM6 : List.img.Prologue6}
            alt=""
            className="chapter-area-info"
          />
        );

      default:
        break;
    }
  };

  return (
    <>
      <div className="prologArea">
        {width > 1000 && page !== 0 && (
          <img
            src={BACK}
            className="prologBack"
            onClick={() => {
              prologChange(0);
            }}
            alt=""
          />
        )}
        <div className="prolog">
          {page !== 0 && (
            <>
              {page !== 1 && (
                <img
                  src={Arrow}
                  className="left"
                  onClick={() => prologBtnChange("prev")}
                  alt=""
                />
              )}
              {page !== 6 && (
                <img
                  src={Arrow}
                  className="right"
                  onClick={() => prologBtnChange("next")}
                  alt=""
                />
              )}
            </>
          )}
          <div
            className="prolog-area"
            style={{
              width:
                page === 1 || page === 2 ? "100%" : page === 0 ? "33.33%" : "0",
            }}
          >
            <div
              className={
                page === 2 ? "chapter chapter1Img2" : "chapter chapter1Img"
              }
              onClick={() => {
                page === 0 && prologChange(1);
              }}
            >
              <div
                className={
                  page !== 0 ? "chapter-area" : "chapter-area hoverPolog"
                }
              >
                {page === 0 || page === 1 || page === 2 ? (
                  <img
                    src={Chapter1}
                    className={
                      page === 1 || page === 2
                        ? "chapter-area-icon"
                        : "chapter-area-icon chapter-area-icon1"
                    }
                    alt=""
                  />
                ) : (
                  ""
                )}
                {page === 1 && textConvert()}

                {page === 2 && textConvert()}
              </div>
            </div>
          </div>
          <div
            className="prolog-area"
            style={{
              width:
                page === 3 || page === 4 ? "100%" : page === 0 ? "33.33%" : "0",
            }}
          >
            <div
              className={
                page === 4 ? "chapter chapter2Img2" : "chapter chapter2Img"
              }
              onClick={() => {
                page === 0 && prologChange(3);
              }}
            >
              <div
                className={
                  page !== 0 ? "chapter-area" : "chapter-area hoverPolog"
                }
              >
                {page === 0 || page === 3 || page === 4 ? (
                  <img
                    src={Chapter2}
                    className={
                      page === 3 || page === 4
                        ? "chapter-area-icon"
                        : "chapter-area-icon chapter-area-icon1"
                    }
                    alt=""
                  />
                ) : (
                  ""
                )}
                {page === 3 && textConvert()}
                {page === 4 && textConvert()}
              </div>
            </div>
          </div>
          <div
            className="prolog-area"
            style={{
              width:
                page === 5 || page === 6 ? "100%" : page === 0 ? "33.33%" : "0",
            }}
          >
            <div
              className={
                page === 6 ? "chapter chapter3Img2" : "chapter chapter3Img"
              }
              onClick={() => {
                page === 0 && prologChange(5);
              }}
            >
              <div
                className={
                  page !== 0 ? "chapter-area" : "chapter-area hoverPolog"
                }
              >
                {page === 0 || page === 5 || page === 6 ? (
                  <img
                    src={Chapter3}
                    className={
                      page === 5 || page === 6
                        ? "chapter-area-icon"
                        : "chapter-area-icon chapter-area-icon1"
                    }
                    alt=""
                  />
                ) : (
                  ""
                )}
                {page === 5 && textConvert()}
                {page === 6 && textConvert()}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default memo(Prolog);
