import LogoWhite from "assets/logo/Logo_US_Intro1.png";
import LogoLight from "assets/logo/Logo_US_Intro2.png";
import AliceTxt from "assets/text/US/US_Hero1_1.png";
import AliceVoice from "assets/text/US/US_Hero1_2.png";
import SnowTxt from "assets/text/US/US_Hero2_1.png";
import SnowVoice from "assets/text/US/US_Hero2_2.png";
import KaguyaTxt from "assets/text/US/US_Hero3_1.png";
import KaguyaVoice from "assets/text/US/US_Hero3_2.png";
import DraculaTxt from "assets/text/US/US_Hero4_1.png";
import DraculaVoice from "assets/text/US/US_Hero4_2.png";
import RoseTxt from "assets/text/US/US_Hero5_1.png";
import RoseVoice from "assets/text/US/US_Hero5_2.png";
import MermaidTxt from "assets/text/US/US_Hero6_1.png";
import MermaidVoice from "assets/text/US/US_Hero6_2.png";
import RabbitTxt from "assets/text/US/US_Hero7_1.png";
import RabbitVoice from "assets/text/US/US_Hero7_2.png";
import Phantasia from "assets/text/US/Phantasia.svg";
import Dream from "assets/text/US/TheDreamless.svg";
import Arura from "assets/text/US/Aurora.svg";
import Merhen from "assets/text/US/Heroes.svg";
import Dominion from "assets/text/US/DominionLords.svg";
import PhantasiaInfo from "assets/text/US/Setting_Phantasia.svg";
import DreamInfo from "assets/text/US/Setting_Dreamless.svg";
import AruraInfo from "assets/text/US/Setting_Aurora.svg";
import MerhenInfo from "assets/text/US/Setting_Hero.svg";
import DominionInfo from "assets/text/US/Setting_DominionLords.svg";
import ResText from "assets/text/US/US_Text4.png";
import WorldLogo from "assets/text/US/US_Text_World1.png";
import HeroLogo from "assets/text/US/US_Text_World12.png";
import MainComent from "assets/text/US/intro.svg";
import MenuMain from "assets/text/US/menu_main.svg";
import MenuProlrogue from "assets/text/US/menu_Prologue.svg";
import MenuWorld from "assets/text/US/menu_World.svg";
import MenuHero from "assets/text/US/menu_Hero.svg";
import MenuPre from "assets/text/US/menu_Pre-registration.svg";
import Twitter from "assets/text/US/GrimlightTwitter.svg";
import Discord from "assets/text/US/GrimlightDiscord.svg";
import Youtube from "assets/text/US/GrimlightYoutube.svg";
import ResTime from "assets/text/USmobile/mobile_US.svg";
import PhantasiaInfo_mobile from "assets/text/USmobile/Setting_PhantasiaUS.svg";
import DreamInfo_mobile from "assets/text/USmobile/Setting_DreamlessUS.svg";
import AruraInfo_mobile from "assets/text/USmobile/Setting_AuroraUS.svg";
import MerhenInfo_mobile from "assets/text/USmobile/Setting_HeroUS.svg";
import DominionInfo_mobile from "assets/text/USmobile/Setting_DominionLordsUS.svg";
import serviceUS from "assets/text/US/serviceUS.svg";
import privaryUS from "assets/text/US/privaryUS.svg";
import BG11 from "assets/img/BG1-1.jpg";
import BG12 from "assets/img/BG1-2.jpg";
import BG21 from "assets/img/BG2-1.jpg";
import BG22 from "assets/img/BG2-2.jpg";
import BG31 from "assets/img/BG3-1.jpg";
import BG32 from "assets/img/BG3-2.jpg";
import Illust1 from "assets/img/Illust9.jpg";
import Illust2 from "assets/img/Illust5.jpg";
import Illust3 from "assets/img/Illust8.jpg";
import Illust4 from "assets/img/Illust6.jpg";
import Illust5 from "assets/img/Illust4.jpg";
import Illust6 from "assets/img/Illust1.jpg";
import Illust7 from "assets/img/Illust10.jpg";
import Illust8 from "assets/img/Illust7-setting.jpg";
import Symbol1 from "assets/img/Chapter_Symbol0.png";
import Symbol2 from "assets/img/Chapter_Symbol1.png";
import Symbol3 from "assets/img/Chapter_Symbol5.png";
import Hero1 from "assets/img/Hero1.png";
import Hero2 from "assets/img/Hero2.png";
import Hero3 from "assets/img/Hero3.png";
import Hero4 from "assets/img/Hero4.png";
import Hero5 from "assets/img/Hero5.png";
import Hero6 from "assets/img/Hero6.png";
import Hero7 from "assets/img/Hero7.png";
import retxt from "assets/text/US/PC_US.svg";
import PreRegistration from "assets/text/US/Pre-RegistrationTextUS.svg";
import PreLine from "assets/text/US/underlineUS.png";
import Prologue1 from "assets/text/US/Prologue_Aurora1US.png";
import Prologue2 from "assets/text/US/Prologue_Aurora2US.png";
import Prologue3 from "assets/text/US/Prologue_Haven1US.png";
import Prologue4 from "assets/text/US/Prologue_Haven2US.png";
import Prologue5 from "assets/text/US/Prologue_Kyo1US.png";
import Prologue6 from "assets/text/US/Prologue_Kyo2US.png";
import PrologueM1 from "assets/text/USmobile/Prologue_Aurora1US.svg";
import PrologueM2 from "assets/text/USmobile/Prologue_Aurora2US.svg";
import PrologueM3 from "assets/text/USmobile/Prologue_Haven1US.svg";
import PrologueM4 from "assets/text/USmobile/Prologue_Haven2US.svg";
import PrologueM5 from "assets/text/USmobile/Prologue_Kyo1US.svg";
import PrologueM6 from "assets/text/USmobile/Prologue_Kyo2US.svg";
import MainGoogle from "assets/text/US/googleplaylightUS.svg";
import MainApple from "assets/text/US/applestorelightUS.svg";
import PreGoogle from "assets/text/US/Download_Button_Google.svg";
import PreApple from "assets/text/US/Download_Button_Appple.svg";
import ALiceVoicePack from "assets/voice/KR/Alice_Evening.wav";
import SnowVoicePack from "assets/voice/KR/Snow_White_Noon.wav";
import KaguyaVoicePack from "assets/voice/KR/Kaguya_Normal_A.wav";
import DraVoicePack from "assets/voice/KR/Dracula_Normal_B.wav";
import RoseVoicePack from "assets/voice/KR/Briar_Rose_Normal_B.wav";
import MerMaidVoicePack from "assets/voice/KR/Little_Mermaid_Normal_B.wav";
import RabbitVoicePack from "assets/voice/KR/White_Rabbit_Unlock.wav";

const images = {
  LogoWhite: LogoWhite,
  LogoLight: LogoLight,
  AliceTxt: AliceTxt,
  AliceVoice: AliceVoice,
  SnowTxt: SnowTxt,
  SnowVoice: SnowVoice,
  KaguyaTxt: KaguyaTxt,
  KaguyaVoice: KaguyaVoice,
  DraculaTxt: DraculaTxt,
  DraculaVoice: DraculaVoice,
  RoseTxt: RoseTxt,
  RoseVoice: RoseVoice,
  MermaidTxt: MermaidTxt,
  MermaidVoice: MermaidVoice,
  RabbitTxt: RabbitTxt,
  RabbitVoice: RabbitVoice,
  Phantasia: Phantasia,
  Dream: Dream,
  Arura: Arura,
  Merhen: Merhen,
  Dominion: Dominion,
  PhantasiaInfo: PhantasiaInfo,
  DreamInfo: DreamInfo,
  AruraInfo: AruraInfo,
  MerhenInfo: MerhenInfo,
  DominionInfo: DominionInfo,
  PhantasiaInfo_mobile: PhantasiaInfo_mobile,
  DreamInfo_mobile: DreamInfo_mobile,
  AruraInfo_mobile: AruraInfo_mobile,
  MerhenInfo_mobile: MerhenInfo_mobile,
  DominionInfo_mobile: DominionInfo_mobile,
  ResText: ResText,
  MenuMain: MenuMain,
  MenuPrologue: MenuProlrogue,
  MenuWorld: MenuWorld,
  MenuCharector: MenuHero,
  MenuRes: MenuPre,
  License: serviceUS,
  Privacy: privaryUS,
  Twitter: Twitter,
  Discord: Discord,
  Youtube: Youtube,
  ResTime: ResTime,
  Country: "US",
  WorldLogo: WorldLogo,
  HeroLogo: HeroLogo,
  MainComent: MainComent,
  BG11: BG11,
  BG12: BG12,
  BG21: BG21,
  BG22: BG22,
  BG31: BG31,
  BG32: BG32,
  Illust1: Illust1,
  Illust2: Illust2,
  Illust3: Illust3,
  Illust4: Illust4,
  Illust5: Illust5,
  Illust6: Illust6,
  Illust7: Illust7,
  Illust8: Illust8,
  Symbol1: Symbol1,
  Symbol2: Symbol2,
  Symbol3: Symbol3,
  Hero1: Hero1,
  Hero2: Hero2,
  Hero3: Hero3,
  Hero4: Hero4,
  Hero5: Hero5,
  Hero6: Hero6,
  Hero7: Hero7,
  retxt: retxt,
  PreRegistrationM: PreRegistration,
  PreLine: PreLine,
  Prologue1: Prologue1,
  Prologue2: Prologue2,
  Prologue3: Prologue3,
  Prologue4: Prologue4,
  Prologue5: Prologue5,
  Prologue6: Prologue6,
  PrologueM1: PrologueM1,
  PrologueM2: PrologueM2,
  PrologueM3: PrologueM3,
  PrologueM4: PrologueM4,
  PrologueM5: PrologueM5,
  PrologueM6: PrologueM6,
  MainGoogle: MainGoogle,
  MainApple: MainApple,
  PreGoogle: PreGoogle,
  PreApple: PreApple,
  ALiceVoicePack: ALiceVoicePack,
  SnowVoicePack: SnowVoicePack,
  KaguyaVoicePack: KaguyaVoicePack,
  DraVoicePack: DraVoicePack,
  RoseVoicePack: RoseVoicePack,
  MerMaidVoicePack: MerMaidVoicePack,
  RabbitVoicePack: RabbitVoicePack,
};

export default images;
