import LogoWhite from "assets/text/JP/logo_JP.svg";
import retxt from "assets/text/JP/retxt.svg";
import WorldLogo from "assets/text/JP/WorldLogo.svg";
import Phantasia from "assets/text/JP/Phantasia.svg";
import Dream from "assets/text/JP/Dream.svg";
import Arura from "assets/text/JP/Arura.svg";
import Merhen from "assets/text/JP/Merhen.svg";
import Dominion from "assets/text/JP/Dominion.svg";
import PhantasiaInfo from "assets/text/JP/PhantasiaInfo.svg";
import DreamInfo from "assets/text/JP/DreamInfo.svg";
import AruraInfo from "assets/text/JP/AruraInfo.svg";
import MerhenInfo from "assets/text/JP/MerhenInfo.svg";
import DominionInfo from "assets/text/JP/DominionInfo.svg";
import HeroLogo from "assets/text/JP/HeroLogo.svg";
import AliceTxt from "assets/text/JP/AliceTxt.svg";
import AliceVoice from "assets/text/JP/AliceVoice.svg";
import RoseTxt from "assets/text/JP/RoseTxt.svg";
import RoseVoice from "assets/text/JP/RoseVoice.svg";
import DraculaTxt from "assets/text/JP/DraculaTxt.svg";
import DraculaVoice from "assets/text/JP/DraculaVoice.svg";
import KaguyaTxt from "assets/text/JP/KaguyaTxt.svg";
import KaguyaVoice from "assets/text/JP/KaguyaVoice.svg";
import MermaidTxt from "assets/text/JP/MermaidTxt.svg";
import MermaidVoice from "assets/text/JP/MermaidVoice.svg";
import SnowTxt from "assets/text/JP/SnowTxt.svg";
import SnowVoice from "assets/text/JP/SnowVoice.svg";
import RabbitTxt from "assets/text/JP/RabbitTxt.svg";
import RabbitVoice from "assets/text/JP/RabbitVoice.svg";
import ResTime from "assets/text/JP/ResTime.svg";
import ResText from "assets/text/JP/ResText.svg";
import BG11 from "assets/img/BG1-1.jpg";
import BG12 from "assets/img/BG1-2.jpg";
import BG21 from "assets/img/BG2-1.jpg";
import BG22 from "assets/img/BG2-2.jpg";
import BG31 from "assets/img/BG3-1.jpg";
import BG32 from "assets/img/BG3-2.jpg";
import Illust1 from "assets/img/Illust9.jpg";
import Illust2 from "assets/img/Illust5.jpg";
import Illust3 from "assets/img/Illust8.jpg";
import Illust4 from "assets/img/Illust6.jpg";
import Illust5 from "assets/img/Illust4.jpg";
import Illust6 from "assets/img/Illust1.jpg";
import Illust7 from "assets/img/Illust10.jpg";
import Illust8 from "assets/img/Illust7-setting.jpg";
import Symbol1 from "assets/img/Chapter_Symbol0.png";
import Symbol2 from "assets/img/Chapter_Symbol1.png";
import Symbol3 from "assets/img/Chapter_Symbol5.png";
import Hero1 from "assets/img/Hero1.png";
import Hero2 from "assets/img/Hero2.png";
import Hero3 from "assets/img/Hero3.png";
import Hero4 from "assets/img/Hero4.png";
import Hero5 from "assets/img/Hero5.png";
import Hero6 from "assets/img/Hero6.png";
import Hero7 from "assets/img/Hero7.png";
import Prologue1 from "assets/text/JP/Prologue_Aurora1.svg";
import Prologue2 from "assets/text/JP/Prologue_Aurora2.svg";
import Prologue3 from "assets/text/JP/Prologue_Haven1.svg";
import Prologue4 from "assets/text/JP/Prologue_Haven2.svg";
import Prologue5 from "assets/text/JP/Prologue_Kyo1.svg";
import Prologue6 from "assets/text/JP/Prologue_Kyo2.svg";
import PrologueM1 from "assets/text/JP/Prologue_AuroraM1.svg";
import PrologueM2 from "assets/text/JP/Prologue_AuroraM2.svg";
import PrologueM3 from "assets/text/JP/Prologue_HavenM1.svg";
import PrologueM4 from "assets/text/JP/Prologue_HavenM2.svg";
import PrologueM5 from "assets/text/JP/Prologue_KyoM1.svg";
import PrologueM6 from "assets/text/JP/Prologue_KyoM2.svg";
import Service from "assets/text/JP/Service.svg";
import privary from "assets/text/JP/privary.svg";
import MainGoogle from "assets/text/JP/MainGoogle.svg";
import MainApple from "assets/text/JP/MainApple.svg";
import PreGoogle from "assets/text/JP/PreGoogle.svg";
import PreApple from "assets/text/JP/PreApple.svg";
import MenuMain from "assets/text/JP/MenuMain.svg";
import MenuProlrogue from "assets/text/JP/MenuProlrogue.svg";
import MenuWorld from "assets/text/JP/MenuWorld.svg";
import MenuHero from "assets/text/JP/MenuHero.svg";
import MenuPre from "assets/text/JP/MenuPre.svg";
import PhantasiaInfo_mobile from "assets/text/JP/Setting_Phantasia.svg";
import DreamInfo_mobile from "assets/text/JP/Setting_Dreamless.svg";
import AruraInfo_mobile from "assets/text/JP/Setting_Aurora.svg";
import MerhenInfo_mobile from "assets/text/JP/Setting_Hero.svg";
import DominionInfo_mobile from "assets/text/JP/Setting_DominionLords.svg";
import ALiceVoicePack from "assets/voice/JP/1.Alice.wav";
import SnowVoicePack from "assets/voice/JP/2.Snow_White.wav";
import KaguyaVoicePack from "assets/voice/JP/3.Kaguya.wav";
import DraVoicePack from "assets/voice/JP/4.Dracula.wav";
import RoseVoicePack from "assets/voice/JP/5.Briar_Rose.wav";
import MerMaidVoicePack from "assets/voice/JP/6.Little_Mermaid.wav";
import RabbitVoicePack from "assets/voice/JP/7.White_Rabbit.wav";

//////////////////

import PreRegistration from "assets/text/US/Pre-RegistrationTextUS.svg";
import PreLine from "assets/text/US/underlineUS.png";
import LogoLight from "assets/logo/Logo_US_Intro2.png";
import MainComent from "assets/text/US/intro.svg";
import Twitter from "assets/text/US/GrimlightTwitter.svg";
import Youtube from "assets/text/US/GrimlightYoutube.svg";
import Discord from "assets/text/US/GrimlightDiscord.svg";
import Cafe from "assets/text/KR/cafe.png";

const images = {
  LogoWhite: LogoWhite,
  LogoLight: LogoLight,
  AliceTxt: AliceTxt,
  AliceVoice: AliceVoice,
  SnowTxt: SnowTxt,
  SnowVoice: SnowVoice,
  KaguyaTxt: KaguyaTxt,
  KaguyaVoice: KaguyaVoice,
  DraculaTxt: DraculaTxt,
  DraculaVoice: DraculaVoice,
  RoseTxt: RoseTxt,
  RoseVoice: RoseVoice,
  MermaidTxt: MermaidTxt,
  MermaidVoice: MermaidVoice,
  RabbitTxt: RabbitTxt,
  RabbitVoice: RabbitVoice,
  Phantasia: Phantasia,
  Dream: Dream,
  Arura: Arura,
  Merhen: Merhen,
  Dominion: Dominion,
  PhantasiaInfo: PhantasiaInfo,
  DreamInfo: DreamInfo,
  AruraInfo: AruraInfo,
  MerhenInfo: MerhenInfo,
  DominionInfo: DominionInfo,
  PhantasiaInfo_mobile: PhantasiaInfo_mobile,
  DreamInfo_mobile: DreamInfo_mobile,
  AruraInfo_mobile: AruraInfo_mobile,
  MerhenInfo_mobile: MerhenInfo_mobile,
  DominionInfo_mobile: DominionInfo_mobile,
  ResText: ResText,
  MenuMain: MenuMain,
  MenuPrologue: MenuProlrogue,
  MenuWorld: MenuWorld,
  MenuCharector: MenuHero,
  MenuRes: MenuPre,
  License: Service,
  Privacy: privary,
  Twitter: Twitter,
  Discord: Discord,
  Cafe: Cafe,
  Youtube: Youtube,
  ResTime: ResTime,
  WorldLogo: WorldLogo,
  HeroLogo: HeroLogo,
  MainComent: MainComent,
  BG11: BG11,
  BG12: BG12,
  BG21: BG21,
  BG22: BG22,
  BG31: BG31,
  BG32: BG32,
  Illust1: Illust1,
  Illust2: Illust2,
  Illust3: Illust3,
  Illust4: Illust4,
  Illust5: Illust5,
  Illust6: Illust6,
  Illust7: Illust7,
  Illust8: Illust8,
  Symbol1: Symbol1,
  Symbol2: Symbol2,
  Symbol3: Symbol3,
  Hero1: Hero1,
  Hero2: Hero2,
  Hero3: Hero3,
  Hero4: Hero4,
  Hero5: Hero5,
  Hero6: Hero6,
  Hero7: Hero7,
  retxt: retxt,
  PreRegistrationM: PreRegistration,
  PreLine: PreLine,
  Prologue1: Prologue1,
  Prologue2: Prologue2,
  Prologue3: Prologue3,
  Prologue4: Prologue4,
  Prologue5: Prologue5,
  Prologue6: Prologue6,
  PrologueM1: PrologueM1,
  PrologueM2: PrologueM2,
  PrologueM3: PrologueM3,
  PrologueM4: PrologueM4,
  PrologueM5: PrologueM5,
  PrologueM6: PrologueM6,
  MainGoogle: MainGoogle,
  MainApple: MainApple,
  PreGoogle: PreGoogle,
  PreApple: PreApple,
  ALiceVoicePack: ALiceVoicePack,
  SnowVoicePack: SnowVoicePack,
  KaguyaVoicePack: KaguyaVoicePack,
  DraVoicePack: DraVoicePack,
  RoseVoicePack: RoseVoicePack,
  MerMaidVoicePack: MerMaidVoicePack,
  RabbitVoicePack: RabbitVoicePack,
  Country: "JP",
};

export default images;
