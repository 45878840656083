import { observer } from "mobx-react";
import World from "components/World";
import { useCallback, useEffect, useState } from "react";
import { worldTypes } from "util/types/worldTypes";

interface Props {
  mobile: boolean;
  page: number;
}

const WorldContainer = ({ mobile }: Props) => {
  const [wPage, setWPage] = useState<number>(0);
  const [worldId, setWorldId] = useState<string>(worldTypes.FANTASYA);
  let backGround = document.querySelector(".world");

  const onClickChangeNav = (id: string) => {
    backGround = document.querySelector(".world");
    const offOpacity = document.querySelector(".opacity");
    const onOpacity = document.getElementById(id);
    if (offOpacity && onOpacity && backGround) {
      offOpacity.classList.remove("opacity");
      onOpacity.classList.add("opacity");
      backGround.classList.replace(worldId, id);
      setWorldId(id);
      if (id === worldTypes.FANTASYA) {
        setWPage(0);
      } else if (id === worldTypes.DREAMER) {
        setWPage(1);
      } else if (id === worldTypes.AURORA) {
        setWPage(2);
      } else if (id === worldTypes.MERHEN) {
        setWPage(3);
      } else if (id === worldTypes.DOMINION) {
        setWPage(4);
      }
    }
  };

  const onClickPage = useCallback(
    (type: string) => {
      if (type === "prev") {
        if (wPage > 0) {
          setWPage(wPage - 1);
        }
      } else if (type === "next")
        if (wPage < 4) {
          setWPage(wPage + 1);
        }
    },
    [wPage]
  );

  const onClickChangeNavMobile = useCallback(() => {
    backGround = document.querySelector(".world");
    switch (wPage) {
      case 0: {
        setWorldId(worldTypes.FANTASYA);
        backGround?.classList.replace(worldId, worldTypes.FANTASYA);
        break;
      }
      case 1: {
        setWorldId(worldTypes.DREAMER);
        backGround?.classList.replace(worldId, worldTypes.DREAMER);
        break;
      }
      case 2: {
        setWorldId(worldTypes.AURORA);
        backGround?.classList.replace(worldId, worldTypes.AURORA);
        break;
      }
      case 3: {
        setWorldId(worldTypes.MERHEN);
        backGround?.classList.replace(worldId, worldTypes.MERHEN);
        break;
      }
      case 4: {
        setWorldId(worldTypes.DOMINION);
        backGround?.classList.replace(worldId, worldTypes.DOMINION);
        break;
      }
    }
  }, [wPage]);

  useEffect(() => {
    onClickChangeNavMobile();
  }, [onClickChangeNavMobile]);

  return (
    <>
      <World
        onClickChangeNav={onClickChangeNav}
        worldId={worldId}
        mobile={mobile}
        onClickPage={onClickPage}
      />
    </>
  );
};

export default observer(WorldContainer);
