import ReservationPageContainer from "containers/ReservationPage/ReservationPageContainer";

interface ReservationPageProps {
  mobile: boolean;
}

const ReservationPage = ({ mobile }: ReservationPageProps) => {
  return (
    <>
      <ReservationPageContainer mobile={mobile} />
    </>
  );
};

export default ReservationPage;
