import KR from "assets/logo/KR.png";
import ENG from "assets/logo/ENG.png";
import JP from "assets/logo/JP.svg";

export enum language {
  KR = "KR",
  JP = "JP",
  US = "US",
}

export const languageList = [
  {
    id: language.KR,
    src: KR,
    address: "https://www.grimlight.co.kr/",
  },
  {
    id: language.JP,
    src: JP,
    address: "https://grimlight.jp/",
  },
  {
    id: language.US,
    src: ENG,
    address: "https://www.grimlight.global/",
  },
];
