import { observer } from "mobx-react";
import Scroll from "components/Scroll";
import { useCallback, useEffect, useState } from "react";
import jspoly from "smoothscroll-polyfill";
import { useSwipeable } from "react-swipeable";

interface Props {}

const ScrollContainer = ({}: Props) => {
  const [page, setPage] = useState<number>(0);
  const [isScroll, setIsScroll] = useState<boolean>(false);
  const [mobile, setMobile] = useState<boolean>(false);
  const [pad, setPad] = useState<boolean>(false);
  const [PvModal, setPvModal] = useState<boolean>(false);
  const [ScMenu, setScMenu] = useState<boolean>(false);
  const [play, setPlay] = useState<boolean>(false);
  const [world, setWorld] = useState<boolean>(false);
  const [pageHeight, setPageheight] = useState<number>(0);

  const scrollCallback = useCallback(
    (e) => {
      if (!isScroll) {
        setIsScroll(true);
        if (e.deltaY > 0) {
          scroll("up");
        } else {
          scroll("down");
        }
      } else {
        return;
      }
    },
    [isScroll]
  );

  const scrollCallbackMobile = useCallback(
    (type: string) => {
      if (!isScroll) {
        setIsScroll(true);
        scroll(type);
      } else {
        return;
      }
    },
    [isScroll]
  );

  const scroll = useCallback(
    (value: string) => {
      if (value === "up") {
        if (page < 4) {
          setPage(page + 1);
          setTimeout(() => {
            setIsScroll(false);
          }, 750);
        } else {
          setIsScroll(false);
        }
      } else if (value === "down") {
        if (page > 0) {
          setPage(page - 1);
          setTimeout(() => {
            setIsScroll(false);
          }, 750);
        } else {
          setIsScroll(false);
        }
      }
    },
    [isScroll, mobile, page]
  );

  const checkWindow = useCallback(() => {
    if (window.innerWidth < 800) {
      setPad(true);
      setWorld(true);
    } else if (window.innerWidth < 1000) {
      setPad(false);
      setWorld(true);
    } else {
      setPad(false);
      setWorld(false);
    }

    if (mobile) {
      setTimeout(() => {
        MovePage(page);
      }, 400);
    } else {
      setTimeout(() => {
        MovePage(page);
      }, 300);
    }
  }, [page]);

  const MovePage = useCallback(
    (pagenumber: number) => {
      setIsScroll(true);
      const inHeight = window.innerHeight;
      setPage(pagenumber);
      if (mobile) {
        setPageheight(page * inHeight);
      } else {
        setPageheight(page * inHeight);
      }
      setTimeout(() => {
        setIsScroll(false);
      }, 750);
    },
    [page, mobile]
  );

  const swiperHandle = useSwipeable({
    onSwipedUp: () => {
      scrollCallbackMobile("up");
    },
    onSwipedDown: () => {
      scrollCallbackMobile("down");
    },
  });

  useEffect(() => {
    const filter = "win16|win32|win64|mac|macintel";
    if (navigator.platform) {
      if (filter.indexOf(navigator.platform.toLowerCase()) > 0) {
        document.addEventListener("wheel", scrollCallback);
      } else {
        setMobile(true);
      }
    }
    return () => document.removeEventListener("wheel", scrollCallback);
  }, [scrollCallback]);

  useEffect(() => {
    window.addEventListener("resize", checkWindow);
    return () => window.removeEventListener("resize", checkWindow);
  }, [checkWindow]);

  useEffect(() => {
    const inHeight = window.innerHeight;
    if (mobile) {
      setPageheight(page * inHeight);
    } else {
      setPageheight(page * inHeight);
    }

    if (page === 3) {
      setPlay(true);
    }
  }, [page]);

  useEffect(() => {
    jspoly.polyfill();
    checkWindow();
  }, []);

  return (
    <>
      <Scroll
        mobile={mobile}
        pad={pad}
        page={page}
        setPage={MovePage}
        swiperHandle={swiperHandle}
        PvModal={PvModal}
        setPvModal={setPvModal}
        ScMenu={ScMenu}
        setScMenu={setScMenu}
        world={world}
        play={play}
        setPlay={setPlay}
        pageHeight={pageHeight}
      />
    </>
  );
};

export default observer(ScrollContainer);
