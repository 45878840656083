import Logo from "assets/logo/Logo_US_Intro2.png";
import Close from "assets/img/icon/Close_Flat.png";
import MenuIcon from "assets/img/icon/Menu.png";
import List from "assets/convert";
import "./Menu.scss";
import React from "react";

interface Props {
  onClickMenuChange: (url: string) => void;
  setIsShow: React.Dispatch<React.SetStateAction<boolean>>;
}

const Menu = ({ onClickMenuChange, setIsShow }: Props) => {
  return (
    <>
      <img
        src={MenuIcon}
        className="menuIcon"
        onClick={() => setIsShow(true)}
        alt=""
      />
      <div className="menu hide">
        <img src={Logo} className="menu-logo" alt="" />
        <img
          src={Close}
          className="menu-close"
          onClick={() => setIsShow(false)}
          alt=""
        />
        <div className="menu-list">
          <div
            className="menu-list-card mainmenu"
            onClick={() => {
              onClickMenuChange("main");
            }}
          >
            <div className="menu-list-card cover" />
            <div className="menu-list-card-text">
              <img src={List.img.MenuMain} alt="" /> &gt;
            </div>
          </div>
          <div
            className="menu-list-card prologmenu"
            onClick={() => {
              onClickMenuChange("prolog");
            }}
          >
            <div className="menu-list-card cover" />
            <div className="menu-list-card-text">
              <img src={List.img.MenuPrologue} alt="" /> &gt;
            </div>
          </div>
          <div
            className="menu-list-card worldmenu"
            onClick={() => {
              onClickMenuChange("world");
            }}
          >
            <div className="menu-list-card cover" />
            <div className="menu-list-card-text">
              <img src={List.img.MenuWorld} alt="" /> &gt;
            </div>
          </div>
          <div
            className="menu-list-card merhenmenu"
            onClick={() => {
              onClickMenuChange("merhen");
            }}
          >
            <div className="menu-list-card cover" />
            <div className="menu-list-card-text">
              <img src={List.img.MenuCharector} alt="" /> &gt;
            </div>
          </div>
          <div
            className="menu-list-card resmenu"
            onClick={() => {
              onClickMenuChange("res");
            }}
          >
            <div className="menu-list-card cover" />
            <div className="menu-list-card-text">
              <img src={List.img.MenuRes} alt="" /> &gt;
            </div>
          </div>
        </div>
        <div className="menu-info">
          <a
            href="https://www.eightstudio.co.kr/blank-6"
            target="_blank"
            rel="noreferrer"
          >
            <img src={List.img.License} alt="" />
          </a>
          <a
            href="https://www.eightstudio.co.kr/blank-7"
            target="_blank"
            rel="noreferrer"
          >
            <img src={List.img.Privacy} alt="" />
          </a>
        </div>
      </div>
    </>
  );
};

export default React.memo(Menu);
