import React from "react";
import List from "assets/convert";
import Copy from "assets/logo/Copyright_Co.png";
import CopyMini from "assets/logo/Copyright_Mini.svg";
import Cafe from "assets/img/icon/Icon_Cafe.png";
import Twitter from "assets/img/icon/Icon_Twitter.png";
import Discord from "assets/img/icon/Icon_Discord.png";
import Youtube from "assets/img/icon/Icon_Youtube.png";
import cafelogo from "assets/logo/cafe.svg";
import "./Reservation.scss";
import { platformApple, platformGoogle, snsTwiiter } from "util/convertAdress";

interface Props {
  mobile: boolean;
}

const Reservation = ({ mobile }: Props) => {
  return (
    <>
      <div className="reservation reservationBackImg1">
        <div className="reservation-cover">
          {List.img.Country === "KR" ? (
            <a
              href="https://cafe.naver.com/grimlight"
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="reservation-cover-sns logocafe"
                id="snsoff"
                src={cafelogo}
                alt=""
              />
            </a>
          ) : (
            <div className="reservation-cover-sns">
              {List.img.Country === "KR" ? (
                <a
                  className={
                    mobile
                      ? "reservation-cover-sns-coverM"
                      : "reservation-cover-sns-cover"
                  }
                  href="https://cafe.naver.com/grimlight"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    className="reservation-cover-sns-cover-img"
                    src={Cafe}
                    alt=""
                  />
                  <img className="font" src={List.img.Cafe} alt="" />
                </a>
              ) : (
                <a
                  className={
                    mobile
                      ? "reservation-cover-sns-coverM"
                      : "reservation-cover-sns-cover"
                  }
                  href="https://discord.gg/2wh4Yr5dNg"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    className="reservation-cover-sns-cover-img"
                    src={Discord}
                    alt=""
                  />
                  <img className="font" src={List.img.Discord} alt="" />
                </a>
              )}
              <a
                className={
                  mobile
                    ? "reservation-cover-sns-coverM"
                    : "reservation-cover-sns-cover"
                }
                href={snsTwiiter()}
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className="reservation-cover-sns-cover-img"
                  src={Twitter}
                  alt=""
                />
                <img src={List.img.Twitter} className="font" alt="" />
              </a>
              <a
                className={
                  mobile
                    ? "reservation-cover-sns-coverM"
                    : "reservation-cover-sns-cover"
                }
                href="https://www.youtube.com/channel/UCPQXLEuUWVx0BPklqUS65eA"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className="reservation-cover-sns-cover-img"
                  src={Youtube}
                  alt=""
                />
                <img src={List.img.Youtube} className="font" alt="" />
              </a>
            </div>
          )}
          <div className="reservation-cover-area">
            <img
              src={List.img.LogoWhite}
              className="reservation-cover-area-logo"
              alt=""
            />
            {!mobile && (
              <img
                src={List.img.ResTime}
                className={
                  List.img.Country === "KR"
                    ? "reservation-cover-area-reser"
                    : "reservation-cover-area-reserUS"
                }
                alt=""
              />
            )}
            <img
              src={List.img.ResText}
              className="reservation-cover-area-text"
              alt=""
            />

            <div className="InfoDownloadArea">
              {mobile && (
                <img
                  className="InfoDownloadArea-img"
                  src={List.img.ResTime}
                  alt=""
                />
              )}

              <div className="InfoDownload">
                <a href={platformGoogle()} target="_blank" rel="noreferrer">
                  <img
                    src={List.img.PreGoogle}
                    alt=""
                    className="InfoDownload-pre"
                  />
                </a>
                <a href={platformApple()} target="_blank" rel="noreferrer">
                  <img
                    src={List.img.PreApple}
                    alt=""
                    className="InfoDownload-pre"
                  />
                </a>
              </div>
            </div>
            <div className="reservation-cover-area-footer">
              <img
                src={Copy}
                alt=""
                className="reservation-cover-area-footer-nomal"
              />
              <img
                src={CopyMini}
                alt=""
                className="reservation-cover-area-footer-mini"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(Reservation);
