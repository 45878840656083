import List from "assets/convert";
export enum worldTypes {
  FANTASYA = "Fantasy",
  DREAMER = "Dream",
  AURORA = "Aurora",
  MERHEN = "Merhen",
  DOMINION = "Dominion",
}

export const worldTypesList = [
  {
    id: worldTypes.FANTASYA,
    src: List.img.Phantasia,
  },
  {
    id: worldTypes.DREAMER,
    src: List.img.Dream,
  },
  {
    id: worldTypes.AURORA,
    src: List.img.Arura,
  },
  {
    id: worldTypes.MERHEN,
    src: List.img.Merhen,
  },
  {
    id: worldTypes.DOMINION,
    src: List.img.Dominion,
  },
];
