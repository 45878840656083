import React, { memo } from "react";
import List from "assets/convert";
import Cafe from "assets/img/icon/Icon_Cafe.png";
import Discord from "assets/img/icon/Icon_Discord.png";
import Twitter from "assets/img/icon/Icon_Twitter.png";
import Youtube from "assets/img/icon/Icon_Youtube.png";
import cafelogo from "assets/logo/cafe.svg";
import playIcon from "assets/img/icon/playStart.svg";
import playArea from "assets/img/icon/playArea.svg";
import "./Main.scss";
import { languageList } from "util/types/language";
import { platformApple, platformGoogle, snsTwiiter } from "util/convertAdress";

interface MainProps {
  setPvModal: React.Dispatch<React.SetStateAction<boolean>>;
  mobile: boolean;
  page: number;
}

const Main = ({ mobile, setPvModal }: MainProps) => {
  const languageListMenu = () => {
    return (
      <div className="InfoArea-monitor-lang">
        {languageList
          .filter((res) => res.id !== List.img.Country)
          .map((res) => {
            return (
              <div className="InfoArea-monitor-lang-texton">
                <a href={res.address}>
                  <img src={res.src} alt="" />
                </a>
              </div>
            );
          })}
        {languageList
          .filter((res) => res.id === List.img.Country)
          .map((res) => {
            return (
              <div className="InfoArea-monitor-lang-textoff">
                <a href={res.address}>
                  <img src={res.src} alt="" />
                </a>
              </div>
            );
          })}
      </div>
    );
  };

  return (
    <>
      <div className="InfoArea InfoAreaBg">
        <div className="InfoArea-monitor">
          {languageListMenu()}
          {List.img.Country === "KR" ? (
            <a
              href="https://cafe.naver.com/grimlight"
              target="_blank"
              rel="noreferrer"
            >
              <img className="logocafe" id="snsoff" src={cafelogo} alt="" />
            </a>
          ) : (
            <div className="InfoArea-monitor-sns" id="snsoff">
              {List.img.Country === "KR" ? (
                <a
                  className="InfoArea-monitor-sns-cover"
                  href="https://cafe.naver.com/grimlight"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    className="InfoArea-monitor-sns-cover-img"
                    src={Cafe}
                    alt=""
                  />
                  <img className="font" src={List.img.Cafe} alt="" />
                </a>
              ) : (
                <a
                  className="InfoArea-monitor-sns-cover"
                  href="https://discord.gg/2wh4Yr5dNg"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    className="InfoArea-monitor-sns-cover-img"
                    src={Discord}
                    alt=""
                  />
                  <img className="font" src={List.img.Discord} alt="" />
                </a>
              )}
              <a
                className="InfoArea-monitor-sns-cover"
                href={snsTwiiter()}
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className="InfoArea-monitor-sns-cover-img"
                  src={Twitter}
                  alt=""
                />
                <img src={List.img.Twitter} className="font" alt="" />
              </a>
              <a
                className="InfoArea-monitor-sns-cover"
                href="https://www.youtube.com/channel/UCPQXLEuUWVx0BPklqUS65eA"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className="InfoArea-monitor-sns-cover-img"
                  src={Youtube}
                  alt=""
                />
                <img src={List.img.Youtube} className="font" alt="" />
              </a>
            </div>
          )}
        </div>

        <div className="InfoTextArea">
          <div className="Info">
            {mobile && (
              <div className="InfoPlayMobile" onClick={() => setPvModal(true)}>
                <img src={playArea} alt="" className="InfoPlayMobile-area" />
                <img src={playIcon} alt="" className="InfoPlayMobile-start" />
              </div>
            )}
            <img
              src={List.img.LogoWhite}
              className={List.img.Country === "US" ? "InfoLogo" : "InfoLogoKR"}
              alt=""
            />

            {mobile && (
              <img
                src={List.img.ResTime}
                className={
                  List.img.Country === "KR"
                    ? "preLineTextArea-textKR"
                    : "preLineTextArea-text"
                }
                alt=""
              />
            )}

            <div className="playIcon" onClick={() => setPvModal(true)}>
              <img src={playArea} alt="" className="playIcon-area" />
              <img src={playIcon} alt="" className="playIcon-start" />
            </div>

            {!mobile && (
              <img
                className={
                  List.img.Country !== "US"
                    ? "ReservationTextKR"
                    : "ReservationText"
                }
                src={List.img.retxt}
                alt=""
              />
            )}

            <div className="InfoDownload">
              <a href={platformGoogle()} target="_blank" rel="noreferrer">
                <img
                  src={List.img.MainGoogle}
                  alt=""
                  className="InfoDownload-main"
                />
              </a>
              <a href={platformApple()} target="_blank" rel="noreferrer">
                <img
                  src={List.img.MainApple}
                  alt=""
                  className="InfoDownload-main"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default memo(Main);
