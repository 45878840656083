import { observer } from "mobx-react";
import Menu from "components/MobileMenu";
import { useCallback, useEffect, useState } from "react";

interface Props {
  setPage: (page: number) => void;
  setScMenu: React.Dispatch<React.SetStateAction<boolean>>;
}

const MobileMenuContainer = ({ setPage, setScMenu }: Props) => {
  const [isShow, setIsShow] = useState<boolean>(false);

  const onClickShowMenu = useCallback(() => {
    const menu = document.querySelector(".MobileMenu");
    if (menu && isShow) {
      setScMenu(true);
      menu.classList.replace("mobilHide", "mobilShow");
    } else if (menu && !isShow) {
      setScMenu(false);
      menu.classList.replace("mobilShow", "mobilHide");
    }
  }, [isShow]);

  useEffect(() => {
    onClickShowMenu();
  }, [onClickShowMenu]);

  const onClickMenuChange = (url: string) => {
    const menu = document.querySelector(".MobileMenu");
    if (menu) {
      switch (url) {
        case "main": {
          menu.classList.replace("mobilShow", "mobilHide");
          setPage(0);
          break;
        }
        case "prolog": {
          menu.classList.replace("mobilShow", "mobilHide");
          setPage(1);
          break;
        }
        case "world": {
          menu.classList.replace("mobilShow", "mobilHide");
          setPage(2);
          break;
        }
        case "merhen": {
          menu.classList.replace("mobilShow", "mobilHide");
          setPage(3);
          break;
        }
        case "res": {
          menu.classList.replace("mobilShow", "mobilHide");
          setPage(4);
          break;
        }
      }

      setIsShow(false);
    }
  };
  return (
    <>
      <Menu onClickMenuChange={onClickMenuChange} setIsShow={setIsShow} />
    </>
  );
};

export default observer(MobileMenuContainer);
