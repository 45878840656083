import React, { memo } from "react";
import Arrow from "assets/img/icon/Arrow_Flat.png";
import IntroFinal from "assets/media/LOOP_ParticlesOnly1_1.mp4";
import List from "assets/convert";
import "./World.scss";
import { worldTypes, worldTypesList } from "util/types/worldTypes";

interface Props {
  onClickChangeNav: (id: string) => void;
  worldId: string;
  mobile: boolean;
  onClickPage: (type: string) => void;
}

const World = ({ onClickChangeNav, worldId, mobile, onClickPage }: Props) => {
  const WorldInfoList = () => {
    switch (worldId) {
      case worldTypes.FANTASYA:
        return (
          <img
            src={
              mobile ? List.img.PhantasiaInfo_mobile : List.img.PhantasiaInfo
            }
            className={
              List.img.Country === "US"
                ? "world-area-text-info FinfoUS"
                : "world-area-text-info Finfo"
            }
            alt=""
          />
        );
      case worldTypes.DREAMER:
        return (
          <img
            src={mobile ? List.img.DreamInfo_mobile : List.img.DreamInfo}
            className={
              List.img.Country === "US"
                ? "world-area-text-info DinfoUS"
                : "world-area-text-info Dinfo"
            }
            alt=""
          />
        );
      case worldTypes.AURORA:
        return (
          <img
            src={mobile ? List.img.AruraInfo_mobile : List.img.AruraInfo}
            className={
              List.img.Country === "US"
                ? "world-area-text-info OinfoUS"
                : "world-area-text-info Oinfo"
            }
            alt=""
          />
        );
      case worldTypes.MERHEN:
        return (
          <img
            src={mobile ? List.img.MerhenInfo_mobile : List.img.MerhenInfo}
            className={
              List.img.Country === "US"
                ? "world-area-text-info MinfoUS"
                : "world-area-text-info Minfo"
            }
            alt=""
          />
        );
      case worldTypes.DOMINION:
        return (
          <img
            src={mobile ? List.img.DominionInfo_mobile : List.img.DominionInfo}
            className={
              List.img.Country === "US"
                ? "world-area-text-info NinfoUS"
                : "world-area-text-info Ninfo"
            }
            alt=""
          />
        );
      default:
        break;
    }
  };

  const WorldMenuList = () => {
    switch (worldId) {
      case worldTypes.FANTASYA:
        return (
          <img
            src={List.img.Phantasia}
            id={worldTypes.FANTASYA}
            className={
              List.img.Country === "KR"
                ? "mobileWorldMenu-card"
                : "mobileWorldMenu-cardU"
            }
            alt=""
          />
        );
      case worldTypes.DREAMER:
        return (
          <img
            src={List.img.Dream}
            id={worldTypes.DREAMER}
            className={
              List.img.Country === "KR"
                ? "mobileWorldMenu-card"
                : "mobileWorldMenu-cardU"
            }
            alt=""
          />
        );
      case worldTypes.AURORA:
        return (
          <img
            src={List.img.Arura}
            id={worldTypes.AURORA}
            className={
              List.img.Country === "KR"
                ? "mobileWorldMenu-card"
                : "mobileWorldMenu-cardU"
            }
            alt=""
          />
        );
      case worldTypes.MERHEN:
        return (
          <img
            src={List.img.Merhen}
            id={worldTypes.MERHEN}
            className={
              List.img.Country === "KR"
                ? "mobileWorldMenu-card"
                : "mobileWorldMenu-cardU"
            }
            alt=""
          />
        );
      case worldTypes.DOMINION:
        return (
          <img
            src={List.img.Dominion}
            id={worldTypes.DOMINION}
            className={
              List.img.Country === "KR"
                ? "mobileWorldMenu-card"
                : "mobileWorldMenu-cardU"
            }
            alt=""
          />
        );
      default:
        break;
    }
  };

  return (
    <>
      <div className="world Fantasy" id={worldId}>
        {!mobile && (
          <video autoPlay muted loop className="WorldVideo">
            <source src={IntroFinal} type="video/mp4" />
          </video>
        )}

        <div className="world-area">
          <div className="world-area-text">
            <img
              src={List.img.WorldLogo}
              className={
                List.img.Country === "KR"
                  ? "world-area-text-title"
                  : "world-area-text-titleU"
              }
              alt=""
            />
            {mobile ? (
              <div className="mobileWorldMenu">
                {worldId !== "Fantasy" && (
                  <img
                    src={Arrow}
                    alt=""
                    className="mobileWorldMenu-prev"
                    onClick={() => {
                      onClickPage("prev");
                    }}
                  />
                )}
                {worldId !== "Dominion" && (
                  <img
                    src={Arrow}
                    alt=""
                    className="mobileWorldMenu-next"
                    onClick={() => {
                      onClickPage("next");
                    }}
                  />
                )}

                <div className="mobileWorldMenu-area-text-nav">
                  {WorldMenuList()}
                </div>
                <div className="mobileWorldMenu-area">
                  {worldTypesList.map((res) => {
                    return (
                      <div
                        key={res.id}
                        className={
                          worldId === res.id
                            ? "mobileWorldMenu-area-listOn"
                            : "mobileWorldMenu-area-list"
                        }
                      />
                    );
                  })}
                </div>
              </div>
            ) : (
              <div className="world-area-text-nav">
                {worldTypesList.map((res) => {
                  return (
                    <img
                      src={res.src}
                      id={res.id}
                      key={res.id}
                      className={
                        worldId === res.id
                          ? "world-area-text-nav-imgnav opacity"
                          : "world-area-text-nav-imgnav"
                      }
                      onClick={() => onClickChangeNav(res.id)}
                      alt=""
                    />
                  );
                })}
              </div>
            )}

            {WorldInfoList()}
          </div>
        </div>
      </div>
    </>
  );
};

export default memo(World);
