import PrologPageContainer from "containers/PrologPage/PrologPageContainer";

interface PrologPageProps {
  page: number;
  mobile: boolean;
}

const PrologPage = ({ page, mobile }: PrologPageProps) => {
  return (
    <>
      <PrologPageContainer page={page} mobile={mobile} />
    </>
  );
};

export default PrologPage;
