import { observer } from "mobx-react";
import Main from "components/Main";

interface Props {
  setPvModal: React.Dispatch<React.SetStateAction<boolean>>;
  mobile: boolean;
  page: number;
}

const MainContainer = ({ mobile, setPvModal, page }: Props) => {
  return (
    <>
      <Main mobile={mobile} setPvModal={setPvModal} page={page} />
    </>
  );
};

export default observer(MainContainer);
