import React, { useEffect } from "react";
import { Route, Switch, BrowserRouter } from "react-router-dom";
import ScrollPage from "page/ScrollPage";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import "util/App.scss";
import { imagesLoader } from "assets/img/imagesLoader";

const App = () => {
  useEffect(() => {
    let images: any[] = [];

    for (let i = 0; i < imagesLoader.length; i++) {
      images[i] = new Image();
      images[i].src = imagesLoader[i];
    }
  }, []);

  const setScreenSize = () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  };

  setScreenSize();

  window.addEventListener("resize", () => setScreenSize());

  return (
    <>
      <BrowserRouter>
        <Route
          render={({ location }) => {
            return (
              <>
                <TransitionGroup className="transition-group">
                  <CSSTransition
                    key={location.key}
                    timeout={500}
                    classNames="fade"
                  >
                    <Switch location={location}>
                      {/* <Route exact path="/" component={IntroPage} /> */}
                      <Route exact path="/" component={ScrollPage} />
                    </Switch>
                  </CSSTransition>
                </TransitionGroup>
              </>
            );
          }}
        />
      </BrowserRouter>
    </>
  );
};

export default App;
