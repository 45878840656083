import ScrollContainer from "containers/Scroll/ScrollContainer";

const ReservationPage = ({}) => {
  return (
    <>
      <ScrollContainer />
    </>
  );
};

export default ReservationPage;
