import React, { useEffect } from "react";
import MainComtainer from "../containers/Main/MainContainer";

interface MainPageProps {
  setPvModal: React.Dispatch<React.SetStateAction<boolean>>;
  mobile: boolean;
  page: number;
}

const MainPage = ({ mobile, setPvModal, page }: MainPageProps) => {
  return (
    <>
      <MainComtainer mobile={mobile} setPvModal={setPvModal} page={page} />
    </>
  );
};

export default MainPage;
