import KRImages from "assets/img/KR";
import USImages from "assets/img/US";
import JPImages from "assets/img/JP";
import "dotenv/config";

const KR = "KR";
const JP = "JP";
const imgList: any = { img: {} };
const location = process.env.REACT_APP_REGION;

if (location === KR) {
  imgList.img = KRImages;
} else if (location === JP) {
  imgList.img = JPImages;
} else {
  imgList.img = USImages;
}

export default imgList;
