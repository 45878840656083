import ScrollDown from "assets/img/icon/SCROLL.png";
import React, { lazy, Suspense } from "react";
import MenuContainer from "containers/Menu/MenuContainer";
import MainPage from "page/MainPage";
import PrologPage from "page/PrologPage";
import ReservationPage from "page/ReservationPage";
import WorldPage from "page/WorldPage";
import MobileMenuContainer from "containers/MobileMenu/MobileMenuContainer";
import "./Scroll.scss";
import { SwipeableHandlers } from "react-swipeable";
import PV from "components/PvOver";

interface Props {
  mobile: boolean;
  page: number;
  pad: boolean;
  setPage: (page: number) => void;
  swiperHandle: SwipeableHandlers;
  PvModal: boolean;
  setPvModal: React.Dispatch<React.SetStateAction<boolean>>;
  ScMenu: boolean;
  setScMenu: React.Dispatch<React.SetStateAction<boolean>>;
  world: boolean;
  play: boolean;
  setPlay: React.Dispatch<React.SetStateAction<boolean>>;
  pageHeight: number;
}

const CharactorPage = lazy(() => import("page/CharacterPage"));

const Scroll = ({
  mobile,
  page,
  pad,
  setPage,
  swiperHandle,
  PvModal,
  setPvModal,
  ScMenu,
  setScMenu,
  world,
  play,
  pageHeight,
}: Props) => {
  return (
    <>
      <div className="scrollBox" id="scrollBox" {...swiperHandle}>
        {PvModal ? <PV setPvModal={setPvModal} /> : ""}
        {mobile ? (
          <MobileMenuContainer setPage={setPage} setScMenu={setScMenu} />
        ) : (
          <MenuContainer setPage={setPage} setScMenu={setScMenu} />
        )}
        <div
          className="scrollBox-area"
          style={{ transform: `translate3d(0px, -${pageHeight}px, 0px)` }}
        >
          <MainPage mobile={pad} setPvModal={setPvModal} page={page} />
          <PrologPage page={page} mobile={pad} />
          <WorldPage mobile={world} page={page} />
          <Suspense
            fallback={
              <div className="charactor">
                <div className="charactor-cover"></div>
              </div>
            }
          >
            <CharactorPage page={page} mobile={pad} />
          </Suspense>
          <ReservationPage mobile={pad} />
        </div>
      </div>
      {ScMenu
        ? ""
        : page !== 4 && (
            <div className="areaboxSc">
              <img
                src={ScrollDown}
                className="scrollArea"
                onClick={() => {
                  setPage(page + 1);
                }}
                alt=""
              />
            </div>
          )}
    </>
  );
};

export default React.memo(Scroll);
