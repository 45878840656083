import WorldContainer from "containers/World/WorldContainer";

interface WorldPageProps {
  mobile: boolean;
  page: number;
}

const WorldPage = ({ mobile, page }: WorldPageProps) => {
  return (
    <>
      <WorldContainer mobile={mobile} page={page} />
    </>
  );
};

export default WorldPage;
