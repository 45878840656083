import { observer } from "mobx-react";
import Reservation from "components/Reservation";
import { useEffect } from "react";

interface Props {
  mobile: boolean;
}

const ReservationPageContainer = ({ mobile }: Props) => {
  useEffect(() => {
    const back = document.querySelector(".reservation");
    let num = 1;
    setInterval(() => {
      if (back) {
        if (num === 1) {
          back.classList.replace("reservationBackImg1", "reservationBackImg2");
          num = 2;
        } else {
          back.classList.replace("reservationBackImg2", "reservationBackImg1");
          num = 1;
        }
      }
    }, 8000);
  }, []);

  return (
    <>
      <Reservation mobile={mobile} />
    </>
  );
};

export default observer(ReservationPageContainer);
